import { Routes, Route } from "react-router-dom";
import MainLayout from "layouts/MainLayout/MainLayout";
import CategoryPage from "pages/CategoryPage/CategoryPage.jsx";
import HomePage from "pages/HomePage/HomePage";
import MainPage from "pages/MainPage/MainPage";
import { SearchContextProvider } from "context/SearchContext";

function App() {
  return (
    <SearchContextProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<MainPage />} />
            <Route path="yntymak/catalog/:id" element={<CategoryPage />} />
            <Route path="7mkr/catalog/:id" element={<CategoryPage />} />

            <Route path="yntymak/menu" element={<HomePage />} />
            <Route path="yntymak/menu/:id" element={<CategoryPage />} />

            <Route path="7mkr/menu" element={<HomePage />} />
            <Route path="7mkr/menu/:id" element={<CategoryPage />} />
          </Route>
        </Routes>
    </SearchContextProvider>
  )
}

export default App;
