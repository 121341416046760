import { Link, useLocation } from 'react-router-dom';
import SearchInput from 'components/SearchInput/SearchInput';
import LogoSrc from 'assets/images/logo.svg';
import styles from './Header.module.scss';
import { useMemo } from 'react';

const Header = () => {
    const location = useLocation();

    const homeLink = useMemo(() => {
        const isYntymak = location?.pathname?.includes('yntymak');
        const is7Mkr = location?.pathname?.includes('7mkr');

        if (isYntymak) return '/yntymak/menu';
        if (is7Mkr) return '/7mkr/menu';

        return '/';
    }, [location?.pathname]);

    return (
        <header className={styles['header']}>
            <Link to={homeLink}>
                <img src={LogoSrc} alt="Ayland logo" className={styles['logo']} />
            </Link>
            <SearchInput />
        </header>
    );
}

export default Header;