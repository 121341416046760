import { useOutletContext } from "react-router-dom";
import CategoryCard from "components/CategoryCard/CategoryCard";
import styles from './HomePage.module.scss';

const CategoryPage = () => {
    const [catalog] = useOutletContext();

    return (
        <div className={styles['categories']}>
            {catalog.map((category, index) => <CategoryCard key={index} category={category} />)}
        </div>
    );
};

export default CategoryPage;