import { useState } from "react";
import ProductCard from "components/ProductCard/ProductCard";
import ProductPopup from "components/ProductPopup/ProductPopup";
import styles from './ProductsList.module.scss';

const ProductsList = ({ title, products }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const body = document.getElementsByTagName('body')[0];


  const handleProductClick = (product) => () => {
    setSelectedProduct({ ...product });
    setPopupOpen(true);
    body.style.overflow = 'hidden';
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setSelectedProduct(null);
    body.style.overflow = 'auto';
  };


    return (
        <>
            <h1 className={styles['title']}>{title}</h1>

            <div className={styles['products']}>
                {products.map((product) => <ProductCard key={product?.id} product={product} onClick={handleProductClick(product)} />)}
            </div>
            <ProductPopup product={selectedProduct} isOpen={isPopupOpen} onClose={handleClosePopup} />
        </>
    )
};

export default ProductsList;