import { createContext, useContext, useState } from "react";

const SearchContext = createContext(null);

export const SearchContextProvider = ({ children }) => {
    const [searchValue, setSearchValue] = useState('');

    const onSearchChange = (value) => setSearchValue(value);
    const clearSearch = () => setSearchValue('');

    return (
        <SearchContext.Provider value={{
            searchValue,
            onSearchChange,
            clearSearch
        }}>
            {children}
        </SearchContext.Provider>
    )
};

export const useSearch = () => {
    const context = useContext(SearchContext);

    return context;
};
