import { useSearch } from 'context/SearchContext';
import styles from './SearchInput.module.scss';
import { ReactComponent as ClearIcon } from 'assets/images/cross.svg';

const SearchInput = () => {
    const { searchValue, onSearchChange, clearSearch } = useSearch();

    const handleChange = (event) => {
        onSearchChange(event.target.value);
    }

    return (
        <div className={styles['search']}>
            <input
                placeholder='Поиск...'
                className={styles['input']}
                value={searchValue}
                onChange={handleChange}
            />
            {searchValue && (
                <button type="button" className={styles['clear-button']} onClick={clearSearch}>
                    <ClearIcon className={styles['clear-icon']} />
                </button>
            )}
        </div>
    )
};

export default SearchInput;