import styles from './ProductCard.module.scss';

const ProductCard = ({ product, onClick }) => {
    return (
        <div className={styles['card']} onClick={onClick}>
            <img src={product?.image} alt={product?.title} loading='lazy' className={styles['card-image']} />
            <div className={styles['card-details']}>
                <h3 className={styles['card-title']}>{product?.title}</h3>
                <p className={styles['card-description']}>{product?.description}</p>
                <p className={styles['card-price']}>{product?.price} сом</p>
            </div>
        </div>
    );
};

export default ProductCard;