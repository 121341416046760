import { useState, useEffect, useCallback, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import ProductsList from 'components/ProductsList/ProductsList';
import { useSearch } from 'context/SearchContext';
import styles from './MainLayout.module.scss';

const yntymakDir = '/data/yntymak/';
const mkr7Dir = '/data/7mkr/';

const MainLayout = () => {
    const [catalog, setCatalog] = useState([]);
    const [products, setProducts] = useState([]);
    const { searchValue } = useSearch();
    const location = useLocation();

    const dir = useMemo(() => {
        const isYntymak = location?.pathname?.includes('yntymak');
        const is7Mkr = location?.pathname?.includes('7mkr');

        if (isYntymak) return yntymakDir;
        if (is7Mkr) return mkr7Dir;

        return yntymakDir;
    }, [location?.pathname]);

    const getData = useCallback((url) => {
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((res) => res.json());
    }, []);

    useEffect(() => {
        getData(`${dir}catalog/catalog.json`).then((data) => setCatalog(data));
        getData(`${dir}products/products.json`).then((data) => setProducts(data));
    }, [getData, dir]);

    const searchResults = useMemo(() => {
        if (!searchValue) return [];

        return products.filter((product) => product?.title?.includes(searchValue));
    }, [searchValue, products]);

    return (
        <div className={styles['wrapper']}>
            <Header />
            <main className={styles['main']}>
                {searchResults?.length ? <ProductsList title="Результаты поиска" products={searchResults} /> : <Outlet context={[catalog, products]} />}
            </main>
            <Footer />
        </div>
    );
};

export default MainLayout;