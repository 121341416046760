import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles['footer']}>
            <p className={styles['copyright']}>Все права защищены. &nbsp; 2024 Ayland</p>
        </footer>
    );
};

export default Footer;