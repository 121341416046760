import { Link } from 'react-router-dom';
import styles from './MainPage.module.scss';

const MainPage = () => {
    return (
        <div className={styles['wrapper']}>
            <h1 className={styles['title']}>Выберите филиал</h1>

            <Link className={styles['link']} to="/yntymak/menu">
                <h2>Парк Ынтымак</h2>
            </Link>

            <Link className={styles['link']} to="/7mkr/menu">
                <h2>7 микрорайон</h2>
            </Link>
        </div>
    );
};

export default MainPage;