import { useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as ArrowPrev } from 'assets/images/arrow-left.svg';
import { ReactComponent as ArrowNext } from 'assets/images/arrow-right.svg';
import styles from './ProductPopup.module.scss';
import clsx from 'clsx';

const ProductPopup = ({ product, isOpen, onClose }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateY(0)' : 'translateY(100%)',
  });

  const productImages = useMemo(() => {
      if (!product?.images) {
          return (
            <div className={styles['image']}>
                <img src={product?.image} alt={product?.title} />
            </div>
          );
    }
    
    return product?.images?.map((image, index) => (
        <div key={index} className={styles['image']}>
          <img src={image} alt={product.title} />
        </div>
      ));
    }, [product]);


    if (!product) return null;

  return (
    <animated.div style={animation} className={clsx(styles['popup'], {
        [styles['open']]: isOpen
    })}>
      <div className={styles['popup-content']}>
        <button className={styles['close-button']} onClick={onClose}>
            <CloseIcon className={styles['close-icon']} />
        </button>
        <div className={styles['content-wrapper']}>
            <div className={styles['carousel-wrapper']}>
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={!!product?.images?.length}
                    useKeyboardArrows={!!product?.images?.length}
                    className={styles['carousel']}
                    renderArrowPrev={(onClick) => !!product?.images?.length ? <ArrowPrev className={clsx(styles['arrows'], styles['arrows__prev'])} onClick={onClick} /> : null}
                    renderArrowNext={(onClick) => !!product?.images?.length ? <ArrowNext className={clsx(styles['arrows'], styles['arrows__next'])} onClick={onClick} /> : null}
                >
                {productImages}
                </Carousel>
            </div>
            <div className={styles['popup-details']}>
            <h2 className={styles['popup-details__title']}>{product.title}</h2>
            <p className={styles['popup-details__description']}>{product.description}</p>
            <p className={styles['popup-details__price']}>{product.price} сом</p>
            {/* <div className={styles['quantity-selector']}>
                <button onClick={() => product?.setQuantity(product?.quantity - 1)}>-</button>
                <span>{product?.quantity}</span>
                <button onClick={() => product?.setQuantity(product?.quantity + 1)}>+</button>
            </div>
            <button className={styles['add-to-cart-button']}>Добавить в корзину</button> */}
            </div>
        </div>
      </div>
    </animated.div>
  );
};

export default ProductPopup;
