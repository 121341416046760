import { useMemo, useEffect } from "react";
import { useOutletContext, useParams, Link, useLocation } from "react-router-dom";
import clsx from 'clsx';
import ProductsList from "components/ProductsList/ProductsList";
import styles from './CategoryPage.module.scss';

const CategoryPage = () => {
    const { id } = useParams();
    const [catalog, products] = useOutletContext();

    const category = useMemo(() => {
        return catalog.find((item) => item.id === +id);
    }, [catalog, id]);

    const data = useMemo(() => {
        return products.filter((product) => product.catalog_id === +id);
    }, [products, id]);

    useEffect(() => {
        const menuItem = document.getElementsByClassName(styles['menu--active'])[0];

        if (menuItem) {
            setTimeout(() => {
                menuItem.scrollIntoView();
            }, 300);
        }
    }, []);

    const location = useLocation();

    const link = useMemo(() => {
        const isYntymak = location?.pathname?.includes('yntymak');
        const is7Mkr = location?.pathname?.includes('7mkr');

        if (isYntymak) return '/yntymak/catalog';
        if (is7Mkr) return '/7mkr/catalog';

        return '/yntymak/catalog';
    }, [location?.pathname]);

    return (
        <div className={styles['wrapper']}>
            {/* Menu */}
            <div className={styles['menu']}>
                {catalog.map((item) => (
                    <Link
                        key={item.id}
                        to={`${link}/${item.id}`}
                        className={clsx(styles['menu--item'], {
                            [styles['menu--active']]: item.id === +id
                        })}
                    >
                        <span className={styles['menu--item-content']}>{item.title}</span>
                    </Link>
                ))}
            </div>
            {/* Menu */}
            <ProductsList title={category?.title} products={data} />
        </div>
    );
};

export default CategoryPage;