import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './CategoryCard.module.scss'
import icecreamSrc from 'assets/images/categories/icecream.png';

const CategoryCard = ({ category }) => {
    const location = useLocation();

    const link = useMemo(() => {
        const isYntymak = location?.pathname?.includes('yntymak');
        const is7Mkr = location?.pathname?.includes('7mkr');

        if (isYntymak) return '/yntymak/menu';
        if (is7Mkr) return '/7mkr/menu';

        return '/yntymak/menu';
    }, [location?.pathname]);

    return (
        <Link to={`${link}/${category.id}`} className={styles['link']}>
            <div className={styles['card']}>
                <h2 className={styles['card-title']}>{category.title || 'Итальяноское мороженое'}</h2>
                <img src={category.image || icecreamSrc} alt="ice" loading='lazy' className={styles['card-image']} />
            </div>
        </Link>
    );
};

export default CategoryCard;